// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("src/assets/images/jsc/top_border.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/images/jsc/dppt.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".header{position:absolute;left:0;top:0;width:100%;height:8.05556vh;z-index:2;justify-content:space-between;padding:0 4.47917vw 0 4.0625vw}.header,.times_s{display:flex;align-items:center;box-sizing:border-box}.times_s{font-size:2.03704vh;color:#fff;font-family:ysbth;font-style:normal;line-height:2.68519vh;padding-top:1.85185vh}.header-content-bg{position:absolute;top:0;left:0;height:100%;width:100%;height:8.05556vh;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 100%}.header-title-text{position:absolute;left:50%;transform:translate3d(-50%,-50%,0);margin-top:5.55556vh;z-index:2;font-size:3.88889vh;font-weight:400;color:#fff;line-height:5.09259vh;letter-spacing:.37037vh;font-family:ysbth;text-shadow:0 0 .92593vh rgba(0,182,205,.8)}@-webkit-keyframes pulsate-fwd{0%{transform:scaleX(1)}30%{transform:scale3d(.8,1.25,1);opacity:1}40%{transform:scaleY(.75);opacity:.5}50%{transform:scale3d(.2,1.15,1);opacity:0}65%{transform:scaleY(.95);opacity:.5}75%{transform:scale3d(.8,1.05,1);opacity:1}to{transform:scaleX(1)}}@keyframes pulsate-fwd{0%{transform:scaleX(1)}30%{transform:scale3d(.8,1.25,1);opacity:1}40%{transform:scaleY(.75);opacity:.5}50%{transform:scale3d(.2,1.15,1);opacity:0}65%{transform:scaleY(.95);opacity:.5}75%{transform:scale3d(.8,1.05,1);opacity:1}to{transform:scaleX(1)}}@-webkit-keyframes pulsates{0%{left:34%}to{left:48%}}@keyframes pulsates{0%{left:34%}to{left:48%}}.margin-l-10{margin-left:.52083vw}.scha{z-index:99;display:flex;align-items:center;cursor:pointer;margin-top:1.85185vh}.dppt,.sny{width:8.48958vw;height:3.7037vh;display:flex;align-items:center;justify-content:center;background-size:100% 100%;font-size:1.48148vh;color:#fff}.dppt{margin-left:.41667vw;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}", ""]);
// Exports
module.exports = exports;
