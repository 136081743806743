<template>
  <div class="normal-layout">
    <mapIframe />
    <i class="bottom_"></i>
    <i class="i_bg"></i>
    <header-component>
      <slot name="header" />
    </header-component>
    <div class="box_9ops">
      <left-component>
        <slot name="left"></slot>
      </left-component>
      <middle-component>
        <slot name="middle"> </slot>
      </middle-component>
      <right-component>
        <slot name="right"></slot>
      </right-component>
      <tc>
        <slot name="tc"></slot>
      </tc>
    </div>
    <div class="router_go">
      <div
        v-for="(item, index) in tab"
        :key="index"
        class=""
        :class="
          select_path == item.id
            ? 'active margin-r-106'
            : 'tab_name margin-r-106'
        "
        @click="select_item(item, index)"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
import mapIframe from "./mapIframe";
import leftComponent from "./left";
import rightComponent from "./right";
import middleComponent from "./middle/index.vue";
import headerComponent from "./header";
import tc from "./tc";
export default {
  components: {
    leftComponent,
    middleComponent,
    rightComponent,
    headerComponent,
    tc,
    mapIframe,
  },
  computed: {
    select_path() {
      // 获取当前路由路径
      const currentPath = this.$route.path;
      // 查找匹配的tab项
      const matchedTab = this.tab.find((item) => item.path === currentPath);
      // 如果找到匹配项,返回其id,否则返回默认值1
      return matchedTab ? matchedTab.id : 1;
    },
  },
  created() {
    const currentPath = this.$route.path;
    const matchedTab = this.tab.find((item) => item.path === currentPath);
    if (matchedTab) {
      this.$store.set("select_path", matchedTab.id);
    }
  },
  data() {
    return {
      tab: [
       { name: "农田管理", path: "/ntgl", id: 1 },
        { name: "物联设备", path: "/wlsb", id: 2 },
        { name: "智慧灌溉", path: "/zhgg", id: 3 },
      ],
    };
  },
  mounted() {
   
  },

  methods: {
    select_item(item, index) {
      this.$store.set("select_path", item.id);
      this.$router.push(item.path);
    },
   
  },
};
</script>

<style lang="scss">
.normal-layout {
  position: relative;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  //overflow: auto;
  padding-bottom: px2vh(42);

  background: linear-gradient(
    90deg,
    rgba(2, 5, 16, 0.77) 13%,
    rgba(2, 5, 16, 0) 31%,
    rgba(0, 0, 0, 0) 51%,
    rgba(2, 5, 16, 0) 71%,
    rgba(2, 5, 16, 0.77) 90%
  );
  opacity: 1;
  // 禁止 normal-layout 内部所有dom元素的鼠标事件
  // 除非该元素拥有class touch-enable
  // 防止各种dom元素 挡住gis地图 影响地图的操作
  pointer-events: none;
  // @include background-image('~src/assets/images/bgnew.png');

  .touch-enable {
    pointer-events: auto;
  }

  .bottom_ {
    position: absolute;
    left: 50%;
    bottom: px2vh(30);
    transform: translate3d(-50%, 0, 0);
    width: 100%;
    height: px2vh(58);
    background-image: url("~src/assets/images/jsc/bottom.png");
    background-size: 100% 100%;
    z-index: 1;
  }
}
.i_bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url("~@/assets/images/jsc/mengban.png");
  background-size: 100% 100%;
  z-index: 1;
}
.router_go {
  position: absolute;
  bottom: px2vh(50);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  .tab_name {
    width: px2vw(125);
    height: px2vh(39);
    font-family: PingFangSC, PingFang SC;
    cursor: pointer;
    pointer-events: auto;
    font-weight: 600;
    font-size: px2vh(20);
    color: #ffffff;
    line-height: px2vh(28);
    text-align: center;
    font-style: normal;
    background: linear-gradient(90deg, #ffffff 0%, #8be3da 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .active {
    width: px2vw(125);
    height: px2vh(39);
    font-family: PingFangSC, PingFang SC;
    cursor: pointer;
    pointer-events: auto;
    font-weight: 600;
    font-size: px2vh(20);
    color: #ffec00; /* 或其他颜色 */
    line-height: px2vh(28);
    text-align: center;
    font-style: normal;
    background: linear-gradient(90deg, #ffffff 0%, #ffec00 100%);
    background-image: url("../../assets/images/jsc/btn_select.png");
    background-size: 100% 100%;
  }
  .margin-r-106 {
    margin-right: px2vw(106);
  }
  &:last-child {
    margin-right: 0;
  }
}
.box_9ops {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
</style>
