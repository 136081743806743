import request from '@/utils/request'
import qs from "query-string";
//登录
export function login_Api(params) {
    return request({
        url: '/dataCenter/doLogin',
        method: 'get',
        params,
    })
}
//获取配置信息
export function getConfig_Api(params) {
    return request({
        url: '/dataCenter/configList' + params,
        method: 'get',
    })
}

//基地简介
export function getBaseInfo_Api(params) {
    return request({
        url: '/bigScreen/v1/getBaseDetail',
        method: 'get',
    })
}
//基地管理
export function getBaseManage_Api(params) {
    return request({
        url: '/bigScreen/v1/getBaseUserList',
        method: 'get',
    })
}
//养殖模式
export function getUnionExpert_Api(params) {
    return request({
        url: '/bigScreen/v1/getBreedMode',
        method: 'get',
    })
}
//云上同福
export function getCloudFriend_Api(params) {
    return request({
        url: '/bigScreen/v1/getVrList',
        method: 'get',
    })
}
//联合专家
export function getUnionExpert_Api1(params) {
    return request({
        url: '/bigScreen/v1/getExpertList',
        method: 'get',
    })
}
//分区面积
export function getArea_Api(params) {
    return request({
        url: '/bigScreen/v1/getLandPartitionAreaList',
        method: 'get',
    })
}
//种养面积
export function getLand_Api(params) {
    return request({
        url: '/bigScreen/v1/getBreedAreaList',
        method: 'get',
    })
}
//数据统计
export function getData_Api(params) {
    return request({
        url: '/bigScreen/v1/getStatisticsData',
        method: 'get',
    })
}
//天气
export function getWeather_Api(params) {
    return request({
        url: '/bigScreen/v1/getFutureWeather',
        method: 'get',
    })
}
//基地实况
export function getBaseNow_Api(params) {
    return request({
        url: '/bigScreen/v1/getMonitorList',
        method: 'get',
    })
}
//检测设备状态
export function getDeviceStatus_Api(params) {
    return request({
        url: '/bigScreen/v1/getIotEnvironmentList',
        method: 'get',
    })
}
//环境检测
export function getEnvironment_Api(params) {
    return request({
        url: '/bigScreen/v1/getEnvironmentDataList',
        method: 'get',
    })
}
// 空气温度土壤
export function getAirTemperature_Api(params) {
    const query = qs.stringify(params);
    return request({
        url: '/bigScreen/v1/getWeatherLogList?' + query,
        method: 'get',
    })
}

//实时水质
export function getWaterQuality_Api1(params) {
    return request({
        url: '/bigScreen/v1/getRealTimeWaterAll',
        method: 'get',
    })
}

//实时水质统计
export function getWaterQuality_Api(params) {
    const query = qs.stringify(params);
    return request({
        url: '/bigScreen/v1/getRealTimeWaterTypeList?' + query,
        method: 'get',
    })
}
//预警信息面板
export function getWarningPanel_Api(params) {
    const query = qs.stringify(params);
    return request({
        url: '/bigScreen/v1/getWarningLogList?=',
        method: 'get',
    })
}
//设备巡检
export function getInspection_Api(params) {
    const query = qs.stringify(params);
    return request({
        url: '/bigScreen/v1/getEnvironmentLogList?' + query,
        method: 'get',
    })
}
//虫情检测
export function getDisease_Api(params) {
    const query = qs.stringify(params);
    return request({
        url: '/bigScreen/v1/getInsectList',
        method: 'get',
    })
}
//虫情测报灯
export function getDiseaseLight_Api(params) {
    const query = qs.stringify(params);
    return request({
        url: '/bigScreen/v1/getInsectForecastData?' + query,
        method: 'get',
    })
}
//土壤墒情数据
export function getSoil_Api(params) {
    const query = qs.stringify(params);
    return request({
        url: '/bigScreen/v1/getSoilMoistureData?' + query,
        method: 'get',
    })
}
//杀虫灯数据
export function getDust_Api(params) {
    const query = qs.stringify(params);
    return request({
        url: '/bigScreen/v1/getInsectKillLightData?' + query,
        method: 'get',
    })
}
//监控明细
export function getMonitorDetail_Api(params) {
    const query = qs.stringify(params);
    return request({
        url: '/bigScreen/v1/getMonitorDetail?' + query,
        method: 'get',
    })
}
//设备灌溉状态
export function getIrrigationStatus_Api() {
    return request({
        url: '/bigScreen/v1/getIrrigateEnvironmentList',
        method: 'get',
    })
}

//设备 id 转 id
export function getIrrigationStatus_Api1(gisId) {
    return request({
        url: '/bigScreen/v1/getIdByGisId?gisId=' + gisId,
        method: 'get',
    })
}
//气象一体机数据
export function getWeatherData_Api(params) {
    const query = qs.stringify(params);
    return request({
        url: '/bigScreen/v1/getWeatherData?' + query,
        method: 'get',
    })
}
//获取地块内容
export function getLandContent_Api(gisId) {
    return request({
        url: '/bigScreen/v1/getPartitionDetail?gisId=' + gisId,
        method: 'get',
    })
}
//获取灌溉统计
export function getIrrigationCount_Api(params) {

    return request({
        url: '/bigScreen/v1/getIrrigateCountList?type=' + params,
        method: 'get',
    })
}
export function getIrrigationCount_Api2() {

    return request({
        url: '/bigScreen/v1/getWaterDataCountList',
        method: 'get',
    })
}

//获取监控
export function getLandjiankong_Api(gisId) {
    return request({
        url: '/bigScreen/v1/getMonitorDetailGisId?gisId=' + gisId,
        method: 'get',
    })
}
//灌溉设备信息
export function getIrrigationDevice_Api(gisId) {
    return request({
        url: '/bigScreen/v1/getEquipmentGgByGisId?gisId=' + gisId,
        method: 'get',
    })
}
