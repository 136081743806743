// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/component_img/btn_bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".title[data-v-146059de]{position:relative;width:21.71875vw;height:3.88889vh;box-sizing:border-box;align-items:center;justify-content:space-between}.title[data-v-146059de],.title .title-box[data-v-146059de]{display:flex}.title .title-box .itim_is[data-v-146059de]{width:.83333vw;height:2.77778vh}.title .title-box .title-text[data-v-146059de]{font-size:2.22222vh;line-height:2.87037vh;color:#fff;font-family:ysbth;font-weight:400;margin-left:.3125vw}.title .btn_cont[data-v-146059de]{width:5vw;height:2.96296vh;display:flex;align-items:center;justify-content:center;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 100%;color:#8efffc;font-size:1.48148vh;font-family:ysbth;cursor:pointer}.title .border_bottoms[data-v-146059de]{position:absolute;left:0;bottom:0;width:21.71875vw;height:.37037vh}.title .titles_3s[data-v-146059de]{position:absolute;left:6.40625vw;display:flex}.title .titles_3s .is[data-v-146059de]{width:.05208vw;height:1.48148vh;background:rgba(83,226,222,.5);margin-right:.88542vw}.title .titles_3s .titles3[data-v-146059de]{font-size:1.85185vh;color:#53e2de;font-family:ysbth;cursor:pointer}", ""]);
// Exports
module.exports = exports;
